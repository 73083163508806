.koko-page__main {
  .flex-col();
  padding-top: 15.2rem;
  position: relative;

  .screen-xl({
    padding-top: 17.6rem;
  });

  > .language-selector {
    position: absolute;
    top: 3.6rem;
    right: 0;

    .screen-md({
      right: 7rem;
    });
  }

  > .header {
    .flex-col();
    align-items: center;

    > .img-logo {
      width: 13.3rem;
      height: auto;
      object-fit: contain;
      filter: drop-shadow(0 0.4rem 0.4rem @purple-100-T40);

      .screen-md({
        width: 21.9rem;
      });
    }
    > .description {
      .title-1();
      margin-top: 3rem;
      margin-bottom: 4rem;
    }
  }
  > .content {
    flex: 1;
  }
  > .footer {
    .flex-col();
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 1rem;

    > .external-links {
      .flex-align-center();
      gap: 1.5rem;

      > .link {
        .body-2();
      }
      > .divider {
        background: @black;
        width: 0.1rem;
        height: 1.6rem;
      }
    }
    > .copyright {
      .flex-col();
      align-items: center;
      margin: 1.5rem;

      > span {
        .body-4();
      }
      > .koko-link {
        margin-top: 0.9rem;

        > img {
          width: 3.4rem;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}
