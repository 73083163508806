.koko-base-layout {
  .flex-col();
  min-height: 100vh;
  position: relative;

  > header {
    height: 4.2rem;
    background: linear-gradient(180deg, @blue-200 0%, @purple-100 100%);
    padding-top: 1rem;
    padding-left: 2rem;

    .screen-md({
      display: none;
    });
  }
  > main {
    .flex-col();
    .flex-center();
    flex: 1;
    padding: 0 2rem;
    background: @gray-400;

    .screen-xs({
      padding: 0 3rem;
    });

    > .btn-back {
      position: absolute;
      top: 5.6rem;
      left: 2rem;

      .screen-md({
        top: 6rem;
        left: 4rem;
      });
      .screen-xl({
        top: 11rem;
        left: 10rem;
      });
    }
    > .page-container {
      .flex-col();
      flex: 1;
      width: 100%;
      max-width: 94rem;

      .screen-sm({
        max-width: 60rem;
      });
      .screen-md({
        max-width: 75rem;
      });
      .screen-lg({
        max-width: 80rem;
      });
      .screen-xl({
        max-width: 94rem;
      });

      > * {
        flex: 1;
      }
    }
  }

  &--sb-text {
    .abs-center();
  }
}
