.koko-page__peer-support__instructions {
  .flex-col();
  flex: 1;
  padding-top: 15.2rem;

  .screen-xl({
    padding-top: 17.6rem;
  });

  > .header {
    .flex-col();
    align-items: center;

    > .title {
      .title-1();
      text-align: center;
      max-width: 35rem;
      margin-top: 3rem;

      .screen-sm({
        max-width: 46rem;
      });
    }
  }
  > .content {
    .flex-center();
    margin-top: 4rem;

    .btn-continue {
      .title-2();
      width: 18.7rem;
      height: 6rem;
    }
  }
  .footer {
    .flex-col();
    .flex-align-center();
    text-align: center;
    margin-top: 7rem;
    padding-bottom: 5rem;

    > .comment {
      .body-2();
      font-style: italic;
      line-height: 1.2em;
      margin-top: 0;
      margin-bottom: 1rem;

      .screen-sm({
        max-width: 44rem;

        > br {
          display: none;
        }
      });
    }
    > .author {
      .caption-2();
    }
  }
}
