.koko-page__faq__instructions {
  flex: 1;

  .screen-md({
    .flex-center();
    .flex-col();
    padding: 8rem 0;
  });

  > .content {
    .flex-col();
    .flex-align-center();
    gap: 3rem;
    margin-top: 19.5rem;

    .screen-md({
      margin-top: 0;
    });

    > .title {
      .title-1();
      text-align: center;
      max-width: 35rem;
    }
    > .desc-texts {
      .flex-col();
      gap: 1.6rem;
      max-width: 46rem;

      > p {
        .body-2();
        margin: 0;
        line-height: 1.3em;
      }
    }
  }
  > .footer {
    .flex-col();
    .flex-align-center();
    gap: 2.4rem;
    margin-top: 4rem;

    .screen-md({
      flex-direction: row;
      gap: 2rem;
    });

    > button {
      .title-2();
      width: 18.7rem;
      height: 6rem;
    }
  }
}
