.koko-language-selector {
  > .koko-select__control {
    position: relative;
    min-height: auto;
    width: fit-content;
    height: 3rem;
    padding-left: 1rem;
    border-radius: 0.5rem;
    background: @purple-100;
    cursor: pointer;

    .screen-md({
      height: 3.8rem;
      min-width: 20rem;
    });

    > .koko-select__value-container {
      padding: 0;
      text-align: center;

      > .koko-select__placeholder,
      > .koko-select__single-value {
        .body-3();
        color: @white;

        .screen-md({
          .body-1();
        });
      }
    }
    > .koko-select__indicators {
      height: 100%;

      > .koko-select__dropdown-indicator {
        padding: 0 0.4rem;

        .screen-md({
          padding: 0 0.2rem;
        });

        > svg {
          width: 2rem;
          color: @white;

          .screen-md({
            width: 3rem;
          });
        }
      }
    }
  }

  > .koko-select__menu {
    display: none;
    border-radius: 0.5rem;

    .screen-md({
      display: block;
    });

    > .koko-select__menu-list {
      max-height: 16rem;

      &::-webkit-scrollbar {
        width: 0.4rem;
      }

      .koko-select__option {
        .flex-align-center();
        justify-content: space-between;
        border-bottom: none;
        padding: 1rem 1rem 1rem 4rem;

        &--is-selected {
          > svg {
            width: 1.6rem;
            height: auto;
            color: @black-50;
          }
        }
      }
    }
  }

  &__mb-menu {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 0;
    background: @white;
    border: 0.1rem solid @black-T15;
    border-radius: 2rem 2rem 0 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    z-index: 999;

    &--open {
      max-height: 90%;
    }

    &-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &__header {
        padding: 2.5rem 1.5rem 1.5rem;

        > .close-handler {
          display: block;
          cursor: pointer;
          position: absolute;
          left: 50%;
          top: 1rem;
          transform: translate(-50%, -50%);
          width: 5.8rem;
          height: 0.4rem;
          border-radius: 0.4rem;
          background-color: @gray-175;
        }

        > h3 {
          .caption-1();
          color: @black-100;
          margin: 0;
        }
      }
      &__options {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      &__option {
        .caption-3();
        padding: 0.8rem 1.5rem;
        color: @black-T70;
        border-top: 0.1rem solid @gray-225;
        cursor: pointer;

        &:last-of-type {
          border-bottom: 0.1rem solid @gray-225;
        }

        &--active {
          background: @purple-100-T10;
        }
      }
    }

    &-action {
      height: 7.2rem;
      padding: 1.5rem 1.5rem 0.5rem;
      box-shadow: 0 0.4rem 0.4rem 0.5rem @black-T25;

      > .btn-close {
        width: 100%;
        height: 5rem;
      }
    }
  }

  // storybook
  &--sb-container {
    height: 100vh;
    max-width: 100%;

    .koko-language-selector {
      position: absolute;
      right: 0;
      top: 0;

      .screen-md({
        position: relative;
      });
    }
  }
}
