.koko-page__helplines {
  .flex-col();
  .flex-align-center();
  padding-top: 6rem;
  text-align: center;

  .screen-md({
    padding-top: 14rem;
  });
  .screen-xl({
    padding-top: 20.5rem;
  });

  > .title {
    .title-1();
    margin: 0;

    > .topic-type {
      text-transform: capitalize;
    }
  }
  > .helplines {
    .flex-col();
    gap: 1rem;
    width: 100%;
    margin: 3rem 0;
    max-width: 46rem;
  }
}
