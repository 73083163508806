.koko-icon {
  width: 3rem;
  height: 3rem;
  object-fit: contain;

  // storybook
  &--sb {
    &-container {
      display: flex;
      flex-wrap: wrap;
      gap: 2.4rem;
    }
    &-cell {
      .inline-flex-col();
      .body-2();
      align-items: center;
      padding: 1.6rem;
      border-radius: 0.4rem;
      border: 1px solid @gray-200;
      color: @black-200;
    }
    &-icon {
      margin-bottom: 1.6rem;
      font-size: 3.2rem;
      text-align: center;
      color: @black-200;
    }
  }
}
