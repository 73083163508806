.koko-link {
  .body-2();
  .normal-transition();
  font-family: Avenir Next;
  line-height: 1em;
  cursor: pointer;

  &--primary {
    color: @blue-100;
  }
  &--secondary {
    color: @purple-100;
  }

  &:hover {
    filter: brightness(0.8);
  }

  // storybook
  &--sb-container {
    .inline-flex-col();
    gap: 2rem;
    max-width: 100%;
  }
}
