@font-face {
  font-family: "Avenir Next";
  font-weight: 900;
  src: url("../static/fonts/AvenirNextLTPro-Heavy.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 900;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-HeavyIt.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  src: url("../static/fonts/AvenirNextLTPro-Bold.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-BoldIt.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  src: url("../static/fonts/AvenirNextLTPro-Demi.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-DemiIt.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  src: url("../static/fonts/AvenirNextLTPro-Medium.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-MediumIt.otf");
}

@font-face {
  font-family: "Avenir Next Test";
  font-weight: 400;
  src: url("../static/fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-It.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 200;
  src: url("../static/fonts/AvenirNextLTPro-UltLt.otf");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 200;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-UltLtIt.otf");
}
