.title-1 {
  font-size: 1.8rem;
  font-weight: 700;
}
.title-2 {
  font-size: 1.6rem;
  font-weight: 700;
}
.title-3 {
  font-size: 1.4rem;
  font-weight: 700;
}
.title-4 {
  font-size: 1.3rem;
  font-weight: 700;
}

.caption-1 {
  font-size: 1.8rem;
  font-weight: 600;
}
.caption-2 {
  font-size: 1.6rem;
  font-weight: 600;
}
.caption-3 {
  font-size: 1.4rem;
  font-weight: 600;
}
.caption-4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.body-1 {
  font-size: 1.8rem;
  font-weight: 500;
}
.body-2 {
  font-size: 1.6rem;
  font-weight: 500;
}
.body-3 {
  font-size: 1.4rem;
  font-weight: 500;
}
.body-4 {
  font-size: 1.2rem;
  font-weight: 500;
}
