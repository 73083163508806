* {
  box-sizing: border-box;

  &:focus {
    outline: none !important;
  }
}

body {
  min-width: @screen-min;
  margin: 0 auto;
  padding: 0;
  font-family: Avenir Next;
  color: @black-100;
}

// scrollbar
*:not(html):not(body) {
  scrollbar-width: thin;
  scrollbar-color: @gray-200 transparent;

  &::-webkit-scrollbar {
    width: 0.6rem;

    &-track {
      border-radius: 1.6rem;
    }
    &-thumb {
      border-radius: 1.6rem;
      background-color: @gray-200;

      &:hover {
        background-color: @gray-100;
      }
    }
  }
}

/* breakpoints */
html {
  font-size: 62.5%;
}

/* storybook */
.sb-show-main.sb-main-padded {
  padding: 0;
}

.kobo-sb-main-padded {
  padding: 1rem;
}
