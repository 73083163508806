.koko-select {
  > .koko-select__control {
    height: 6rem;
    border-radius: 1rem;
    border: 0.1rem solid @gray-200;
    background: @white;

    &:hover,
    &--is-focused,
    &--menu-is-open {
      border-color: @purple-100;
      box-shadow: none;
    }

    &--menu-is-open {
      .koko-select__dropdown-indicator > svg {
        transform: rotate(180deg);
      }
    }

    > .koko-select__value-container {
      padding: 0.5rem 2rem;
      font-size: 1.6rem;
      font-weight: 500;

      > .koko-select__placeholder {
        color: @gray-100;
      }
      > .koko-select__single-value,
      > .koko-select__input-container {
        color: @black-100;
      }
    }
    > .koko-select__indicators {
      > .koko-select__indicator-separator {
        display: none;
      }
      > .koko-select__clear-indicator {
        .normal-transition();
        width: 1.8rem;
        height: 1.8rem;
        padding: 0;
        background: @gray-200;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        > svg {
          width: 100%;
          height: 100%;
          color: @white;
        }
      }
      > .koko-select__dropdown-indicator {
        padding: 1rem;

        > svg {
          .normal-transition();
          width: 3rem;
          color: @black-200;
        }
      }
    }
  }

  > .koko-select__menu {
    border-radius: 1rem;
    background: @white;

    > .koko-select__menu-list {
      max-height: 30rem;
      padding: 0;
      font-size: 1.6rem;
      color: @black-100;

      .koko-select__menu-notice {
        padding: 2rem;
      }
      .koko-select__option {
        margin: 0;
        padding: 1.4rem 2rem;
        border-bottom: 0.1rem solid @gray-200;

        &:last-of-type {
          border-bottom: none;
        }

        &--is-focused {
          background: @purple-100-T10;
        }
        &--is-selected {
          background: @purple-100-T10;
          color: @black-100;
        }
      }
    }
  }

  // storybook
  &--sb-container {
    width: 40rem;
    max-width: 100%;
  }
}
