// media query
.screen-xs (@rules) {
  @media (min-width: @screen-xs) {
    @rules();
  }
}
.screen-sm (@rules) {
  @media (min-width: @screen-sm) {
    @rules();
  }
}
.screen-md (@rules) {
  @media (min-width: @screen-md) {
    @rules();
  }
}
.screen-lg (@rules) {
  @media (min-width: @screen-lg) {
    @rules();
  }
}
.screen-xl (@rules) {
  @media (min-width: @screen-xl) {
    @rules();
  }
}

// css snippets
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.inline-flex-col {
  display: inline-flex;
  flex-direction: column;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.inline-flex-align-center {
  display: inline-flex;
  align-items: center;
}

// transitions
.fast-transition {
  transition: all 0.1s ease-out;
}

.normal-transition {
  transition: all 0.3s ease-out;
}

.slow-transition {
  transition: all 1s ease-out;
}

// positions
.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
