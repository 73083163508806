.koko-page__peer-support__platforms {
  .flex-col();
  flex: 1;
  padding-top: 15.2rem;

  .screen-xl({
    padding-top: 17.6rem;
  });

  > .header {
    .flex-col();
    align-items: center;

    > .title {
      .title-1();
      text-align: center;
      max-width: 35rem;
      margin-top: 3rem;
    }
    > .subtitle {
      .body-1();
      max-width: 35rem;
      margin-top: 3rem;
      margin-bottom: 4rem;
      text-align: center;
    }
  }
  > .content {
    flex: 1;
  }
}
