.koko-country-selector {
  padding-bottom: 0;

  > button {
    .body-2();
    .normal-transition();
    border: 0.1rem solid @gray-200;
    border-radius: 1rem;
    padding: 1.8rem 2rem;
    color: @gray-100;

    &::after {
      .normal-transition();
      width: 0.8rem;
      height: 0.8rem;
      border-style: solid;
      border-width: 0.2rem 0.2rem 0 0;
      border-color: @black;
      transform: rotate(-45deg);
    }

    &[aria-expanded="true"] {
      border-color: @purple-100;

      &::after {
        width: 0.8rem;
        height: 0.8rem;
        border-style: solid;
        border-width: 0.2rem 0.2rem 0 0;
        border-color: @black;
        transform: rotate(135deg);
      }
    }

    > span {
      .body-2();
      margin: 0;
      padding: 0;
      color: @gray-100;
      font-family: Avenir Next;

      > span:first-child {
        font-size: 2.5rem;
      }
      > span:nth-of-type(2) {
        .body-2();
        padding-left: 2.5rem;
        color: @black-100;
        line-height: 1em;
      }
    }
  }
  > ul {
    max-height: 21.6rem;
    border-radius: 1rem;
    background: @white;
    padding: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    > li {
      padding: 1.4rem 2rem;
      margin: 0;
      border-bottom: 0.1rem solid @gray-200;

      &:hover {
        background: @purple-100-T10;
      }

      &:last-of-type {
        border-bottom: none;
      }

      > span > span {
        &:first-child {
          font-size: 2.5rem;
        }
        &:nth-of-type(2) {
          .body-2();
          padding-left: 2.5rem;
          color: @black-100;
          line-height: 1em;
        }
      }
    }
  }

  // storybook
  &--sb-container {
    width: 40rem;
    max-width: 100%;
  }
}
