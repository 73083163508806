.koko-page__faq__introduction {
  .flex-col();
  .flex-center();

  > .description {
    .body-2();
    line-height: 1.2em;
    text-align: center;
    margin: 0;

    .screen-sm({
      > br:first-of-type {
        display: none;
      }
    });
  }
  > .btn-continue {
    .title-2();
    margin-top: 4rem;
    width: 18.7rem;
    height: 6rem;
  }
}
