.koko-button {
  .flex-center();
  .normal-transition();
  gap: 0.6rem;
  font-family: Avenir Next;
  line-height: 1em;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
  }

  &--primary,
  &--secondary,
  &--default {
    .title-2();
    padding: 2rem 4rem;
    border-radius: 4rem;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &--primary {
    color: @white;
    background: @purple-100;
    border: 0.1rem solid @purple-100;
  }
  &--secondary {
    color: @black-T85;
    background: @gray-250;
    border: 0.1rem solid @gray-250;
  }
  &--default {
    color: @purple-100;
    background: @white;
    border: 0.1rem solid @purple-100;
  }
  &--text {
    .caption-4();
    width: fit-content;
    padding: 0;
    background: none;
    border: none;
    color: @black-100;

    &:hover {
      color: @black-200;
    }
  }

  svg&__icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-bottom: 0.1rem;
  }

  // storybook
  &--sb-container {
    .inline-flex-col();
    gap: 2rem;
  }
}
