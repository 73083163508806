.koko-helpline-card {
  .flex-col();
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 0.1rem solid @gray-200;
  background: @white;

  &__head {
    .flex-align-center();
    justify-content: space-between;
    gap: 2rem;

    > a,
    > span {
      .caption-2();
      line-height: 1.2em;
      text-align: left;
    }
    > .availability {
      .title-4();
      margin: 0;
      max-width: 11rem;
      color: @green-100;
      text-align: right;
    }
  }
  &__body {
    .flex-col();
    justify-content: flex-end;
    flex: 1;
    margin-top: 2rem;

    > .description {
      .body-2();
      margin: 0;
      text-align: left;
    }
    > .phone {
      .flex-align-center();
      .body-2();
      line-height: 1em;
      margin-top: 2rem;
      gap: 1rem;

      > svg {
        width: 1.3rem;
        height: 1.3rem;
        margin-bottom: 0.1rem;
        color: @gray-300;
      }
    }
  }
  &__foot {
    > .btn-call {
      margin-top: 2rem;
      width: 10rem;
      height: 4.2rem;
      padding: 0;

      > svg {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  // storybook
  &--sb-container {
    width: 35rem;
    max-width: 100%;
  }
}
