* {
  box-sizing: border-box;
}
*:focus {
  outline: none !important;
}
body {
  min-width: 320px;
  margin: 0 auto;
  padding: 0;
  font-family: Avenir Next;
  color: #1e1e1e;
}
*:not(html):not(body) {
  scrollbar-width: thin;
  scrollbar-color: #cbcbcb transparent;
}
*:not(html):not(body)::-webkit-scrollbar {
  width: 0.6rem;
}
*:not(html):not(body)::-webkit-scrollbar-track {
  border-radius: 1.6rem;
}
*:not(html):not(body)::-webkit-scrollbar-thumb {
  border-radius: 1.6rem;
  background-color: #cbcbcb;
}
*:not(html):not(body)::-webkit-scrollbar-thumb:hover {
  background-color: #acaaaa;
}
/* breakpoints */
html {
  font-size: 62.5%;
}
/* storybook */
.sb-show-main.sb-main-padded {
  padding: 0;
}
.kobo-sb-main-padded {
  padding: 1rem;
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 900;
  src: url("../static/fonts/AvenirNextLTPro-Heavy.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 900;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-HeavyIt.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  src: url("../static/fonts/AvenirNextLTPro-Bold.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-BoldIt.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  src: url("../static/fonts/AvenirNextLTPro-Demi.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-DemiIt.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  src: url("../static/fonts/AvenirNextLTPro-Medium.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-MediumIt.otf");
}
@font-face {
  font-family: "Avenir Next Test";
  font-weight: 400;
  src: url("../static/fonts/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-It.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 200;
  src: url("../static/fonts/AvenirNextLTPro-UltLt.otf");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 200;
  font-style: italic;
  src: url("../static/fonts/AvenirNextLTPro-UltLtIt.otf");
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.inline-flex-col {
  display: inline-flex;
  flex-direction: column;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.inline-flex-align-center {
  display: inline-flex;
  align-items: center;
}
.fast-transition {
  transition: all 0.1s ease-out;
}
.normal-transition {
  transition: all 0.3s ease-out;
}
.slow-transition {
  transition: all 1s ease-out;
}
.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.title-1 {
  font-size: 1.8rem;
  font-weight: 700;
}
.title-2 {
  font-size: 1.6rem;
  font-weight: 700;
}
.title-3 {
  font-size: 1.4rem;
  font-weight: 700;
}
.title-4 {
  font-size: 1.3rem;
  font-weight: 700;
}
.caption-1 {
  font-size: 1.8rem;
  font-weight: 600;
}
.caption-2 {
  font-size: 1.6rem;
  font-weight: 600;
}
.caption-3 {
  font-size: 1.4rem;
  font-weight: 600;
}
.caption-4 {
  font-size: 1.2rem;
  font-weight: 600;
}
.body-1 {
  font-size: 1.8rem;
  font-weight: 500;
}
.body-2 {
  font-size: 1.6rem;
  font-weight: 500;
}
.body-3 {
  font-size: 1.4rem;
  font-weight: 500;
}
.body-4 {
  font-size: 1.2rem;
  font-weight: 500;
}
.koko-base-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
.koko-base-layout > header {
  height: 4.2rem;
  background: linear-gradient(180deg, #75b0ff 0%, #7059e7 100%);
  padding-top: 1rem;
  padding-left: 2rem;
}
@media (min-width: 1024px) {
  .koko-base-layout > header {
    display: none;
  }
}
.koko-base-layout > main {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 2rem;
  background: #f9f9f9;
}
@media (min-width: 425px) {
  .koko-base-layout > main {
    padding: 0 3rem;
  }
}
.koko-base-layout > main > .btn-back {
  position: absolute;
  top: 5.6rem;
  left: 2rem;
}
@media (min-width: 1024px) {
  .koko-base-layout > main > .btn-back {
    top: 6rem;
    left: 4rem;
  }
}
@media (min-width: 1440px) {
  .koko-base-layout > main > .btn-back {
    top: 11rem;
    left: 10rem;
  }
}
.koko-base-layout > main > .page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 94rem;
}
@media (min-width: 768px) {
  .koko-base-layout > main > .page-container {
    max-width: 60rem;
  }
}
@media (min-width: 1024px) {
  .koko-base-layout > main > .page-container {
    max-width: 75rem;
  }
}
@media (min-width: 1280px) {
  .koko-base-layout > main > .page-container {
    max-width: 80rem;
  }
}
@media (min-width: 1440px) {
  .koko-base-layout > main > .page-container {
    max-width: 94rem;
  }
}
.koko-base-layout > main > .page-container > * {
  flex: 1;
}
.koko-base-layout--sb-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.koko-button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  gap: 0.6rem;
  font-family: Avenir Next;
  line-height: 1em;
  cursor: pointer;
}
.koko-button:disabled {
  opacity: 0.6;
}
.koko-button--primary,
.koko-button--secondary,
.koko-button--default {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 2rem 4rem;
  border-radius: 4rem;
}
.koko-button--primary:hover,
.koko-button--secondary:hover,
.koko-button--default:hover {
  filter: brightness(0.8);
}
.koko-button--primary {
  color: #ffffff;
  background: #7059e7;
  border: 0.1rem solid #7059e7;
}
.koko-button--secondary {
  color: rgba(0, 0, 0, 0.85);
  background: #d8d8dc;
  border: 0.1rem solid #d8d8dc;
}
.koko-button--default {
  color: #7059e7;
  background: #ffffff;
  border: 0.1rem solid #7059e7;
}
.koko-button--text {
  font-size: 1.2rem;
  font-weight: 600;
  width: fit-content;
  padding: 0;
  background: none;
  border: none;
  color: #1e1e1e;
}
.koko-button--text:hover {
  color: #333333;
}
svg.koko-button__icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-bottom: 0.1rem;
}
.koko-button--sb-container {
  display: inline-flex;
  flex-direction: column;
  gap: 2rem;
}
.koko-country-selector {
  padding-bottom: 0;
}
.koko-country-selector > button {
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.3s ease-out;
  border: 0.1rem solid #cbcbcb;
  border-radius: 1rem;
  padding: 1.8rem 2rem;
  color: #acaaaa;
}
.koko-country-selector > button::after {
  transition: all 0.3s ease-out;
  width: 0.8rem;
  height: 0.8rem;
  border-style: solid;
  border-width: 0.2rem 0.2rem 0 0;
  border-color: #000000;
  transform: rotate(-45deg);
}
.koko-country-selector > button[aria-expanded="true"] {
  border-color: #7059e7;
}
.koko-country-selector > button[aria-expanded="true"]::after {
  width: 0.8rem;
  height: 0.8rem;
  border-style: solid;
  border-width: 0.2rem 0.2rem 0 0;
  border-color: #000000;
  transform: rotate(135deg);
}
.koko-country-selector > button > span {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #acaaaa;
  font-family: Avenir Next;
}
.koko-country-selector > button > span > span:first-child {
  font-size: 2.5rem;
}
.koko-country-selector > button > span > span:nth-of-type(2) {
  font-size: 1.6rem;
  font-weight: 500;
  padding-left: 2.5rem;
  color: #1e1e1e;
  line-height: 1em;
}
.koko-country-selector > ul {
  max-height: 21.6rem;
  border-radius: 1rem;
  background: #ffffff;
  padding: 0;
  scrollbar-width: none;
}
.koko-country-selector > ul::-webkit-scrollbar {
  display: none;
}
.koko-country-selector > ul > li {
  padding: 1.4rem 2rem;
  margin: 0;
  border-bottom: 0.1rem solid #cbcbcb;
}
.koko-country-selector > ul > li:hover {
  background: rgba(112, 89, 231, 0.1);
}
.koko-country-selector > ul > li:last-of-type {
  border-bottom: none;
}
.koko-country-selector > ul > li > span > span:first-child {
  font-size: 2.5rem;
}
.koko-country-selector > ul > li > span > span:nth-of-type(2) {
  font-size: 1.6rem;
  font-weight: 500;
  padding-left: 2.5rem;
  color: #1e1e1e;
  line-height: 1em;
}
.koko-country-selector--sb-container {
  width: 40rem;
  max-width: 100%;
}
.koko-cta-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.koko-cta-list__item {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
  width: 100%;
  justify-content: space-between;
  height: 8rem;
  border-top: 0.1rem solid #cbcbcb;
  cursor: pointer;
}
.koko-cta-list__item:last-of-type {
  border-bottom: 0.1rem solid #cbcbcb;
}
.koko-cta-list__item:hover {
  opacity: 0.8;
}
.koko-cta-list__item > .left-panel {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.koko-cta-list__item > .left-panel > svg {
  margin-left: 1.2rem;
  width: 5rem;
  height: 5rem;
}
.koko-cta-list__item > .left-panel > span {
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 0.5rem;
  color: #1e1e1e;
}
.koko-cta-list__item > .right-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.koko-cta-list__item > .right-panel > svg {
  color: #007a36;
  width: 2.4rem;
  height: 2.4rem;
}
.koko-cta-list--sb-container {
  width: 35rem;
  max-width: 100%;
}
.koko-helpline-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #cbcbcb;
  background: #ffffff;
}
.koko-helpline-card__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.koko-helpline-card__head > a,
.koko-helpline-card__head > span {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2em;
  text-align: left;
}
.koko-helpline-card__head > .availability {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0;
  max-width: 11rem;
  color: #007a36;
  text-align: right;
}
.koko-helpline-card__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  margin-top: 2rem;
}
.koko-helpline-card__body > .description {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}
.koko-helpline-card__body > .phone {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1em;
  margin-top: 2rem;
  gap: 1rem;
}
.koko-helpline-card__body > .phone > svg {
  width: 1.3rem;
  height: 1.3rem;
  margin-bottom: 0.1rem;
  color: #7b7b7b;
}
.koko-helpline-card__foot > .btn-call {
  margin-top: 2rem;
  width: 10rem;
  height: 4.2rem;
  padding: 0;
}
.koko-helpline-card__foot > .btn-call > svg {
  width: 1.3rem;
  height: 1.3rem;
}
.koko-helpline-card--sb-container {
  width: 35rem;
  max-width: 100%;
}
.koko-icon {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}
.koko-icon--sb-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
}
.koko-icon--sb-cell {
  display: inline-flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
  padding: 1.6rem;
  border-radius: 0.4rem;
  border: 1px solid #cbcbcb;
  color: #333333;
}
.koko-icon--sb-icon {
  margin-bottom: 1.6rem;
  font-size: 3.2rem;
  text-align: center;
  color: #333333;
}
.koko-link {
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.3s ease-out;
  font-family: Avenir Next;
  line-height: 1em;
  cursor: pointer;
}
.koko-link--primary {
  color: #0075ff;
}
.koko-link--secondary {
  color: #7059e7;
}
.koko-link:hover {
  filter: brightness(0.8);
}
.koko-link--sb-container {
  display: inline-flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 100%;
}
.koko-select > .koko-select__control {
  height: 6rem;
  border-radius: 1rem;
  border: 0.1rem solid #cbcbcb;
  background: #ffffff;
}
.koko-select > .koko-select__control:hover,
.koko-select > .koko-select__control--is-focused,
.koko-select > .koko-select__control--menu-is-open {
  border-color: #7059e7;
  box-shadow: none;
}
.koko-select > .koko-select__control--menu-is-open .koko-select__dropdown-indicator > svg {
  transform: rotate(180deg);
}
.koko-select > .koko-select__control > .koko-select__value-container {
  padding: 0.5rem 2rem;
  font-size: 1.6rem;
  font-weight: 500;
}
.koko-select > .koko-select__control > .koko-select__value-container > .koko-select__placeholder {
  color: #acaaaa;
}
.koko-select > .koko-select__control > .koko-select__value-container > .koko-select__single-value,
.koko-select > .koko-select__control > .koko-select__value-container > .koko-select__input-container {
  color: #1e1e1e;
}
.koko-select > .koko-select__control > .koko-select__indicators > .koko-select__indicator-separator {
  display: none;
}
.koko-select > .koko-select__control > .koko-select__indicators > .koko-select__clear-indicator {
  transition: all 0.3s ease-out;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
  background: #cbcbcb;
  border-radius: 50%;
  cursor: pointer;
}
.koko-select > .koko-select__control > .koko-select__indicators > .koko-select__clear-indicator:hover {
  opacity: 0.8;
}
.koko-select > .koko-select__control > .koko-select__indicators > .koko-select__clear-indicator > svg {
  width: 100%;
  height: 100%;
  color: #ffffff;
}
.koko-select > .koko-select__control > .koko-select__indicators > .koko-select__dropdown-indicator {
  padding: 1rem;
}
.koko-select > .koko-select__control > .koko-select__indicators > .koko-select__dropdown-indicator > svg {
  transition: all 0.3s ease-out;
  width: 3rem;
  color: #333333;
}
.koko-select > .koko-select__menu {
  border-radius: 1rem;
  background: #ffffff;
}
.koko-select > .koko-select__menu > .koko-select__menu-list {
  max-height: 30rem;
  padding: 0;
  font-size: 1.6rem;
  color: #1e1e1e;
}
.koko-select > .koko-select__menu > .koko-select__menu-list .koko-select__menu-notice {
  padding: 2rem;
}
.koko-select > .koko-select__menu > .koko-select__menu-list .koko-select__option {
  margin: 0;
  padding: 1.4rem 2rem;
  border-bottom: 0.1rem solid #cbcbcb;
}
.koko-select > .koko-select__menu > .koko-select__menu-list .koko-select__option:last-of-type {
  border-bottom: none;
}
.koko-select > .koko-select__menu > .koko-select__menu-list .koko-select__option--is-focused {
  background: rgba(112, 89, 231, 0.1);
}
.koko-select > .koko-select__menu > .koko-select__menu-list .koko-select__option--is-selected {
  background: rgba(112, 89, 231, 0.1);
  color: #1e1e1e;
}
.koko-select--sb-container {
  width: 40rem;
  max-width: 100%;
}
.koko-language-selector > .koko-select__control {
  position: relative;
  min-height: auto;
  width: fit-content;
  height: 3rem;
  padding-left: 1rem;
  border-radius: 0.5rem;
  background: #7059e7;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .koko-language-selector > .koko-select__control {
    height: 3.8rem;
    min-width: 20rem;
  }
}
.koko-language-selector > .koko-select__control > .koko-select__value-container {
  padding: 0;
  text-align: center;
}
.koko-language-selector > .koko-select__control > .koko-select__value-container > .koko-select__placeholder,
.koko-language-selector > .koko-select__control > .koko-select__value-container > .koko-select__single-value {
  font-size: 1.4rem;
  font-weight: 500;
  color: #ffffff;
}
@media (min-width: 1024px) {
  .koko-language-selector > .koko-select__control > .koko-select__value-container > .koko-select__placeholder,
  .koko-language-selector > .koko-select__control > .koko-select__value-container > .koko-select__single-value {
    font-size: 1.8rem;
    font-weight: 500;
  }
}
.koko-language-selector > .koko-select__control > .koko-select__indicators {
  height: 100%;
}
.koko-language-selector > .koko-select__control > .koko-select__indicators > .koko-select__dropdown-indicator {
  padding: 0 0.4rem;
}
@media (min-width: 1024px) {
  .koko-language-selector > .koko-select__control > .koko-select__indicators > .koko-select__dropdown-indicator {
    padding: 0 0.2rem;
  }
}
.koko-language-selector > .koko-select__control > .koko-select__indicators > .koko-select__dropdown-indicator > svg {
  width: 2rem;
  color: #ffffff;
}
@media (min-width: 1024px) {
  .koko-language-selector > .koko-select__control > .koko-select__indicators > .koko-select__dropdown-indicator > svg {
    width: 3rem;
  }
}
.koko-language-selector > .koko-select__menu {
  display: none;
  border-radius: 0.5rem;
}
@media (min-width: 1024px) {
  .koko-language-selector > .koko-select__menu {
    display: block;
  }
}
.koko-language-selector > .koko-select__menu > .koko-select__menu-list {
  max-height: 16rem;
}
.koko-language-selector > .koko-select__menu > .koko-select__menu-list::-webkit-scrollbar {
  width: 0.4rem;
}
.koko-language-selector > .koko-select__menu > .koko-select__menu-list .koko-select__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
  padding: 1rem 1rem 1rem 4rem;
}
.koko-language-selector > .koko-select__menu > .koko-select__menu-list .koko-select__option--is-selected > svg {
  width: 1.6rem;
  height: auto;
  color: #1a1a1a;
}
.koko-language-selector__mb-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 0;
  background: #ffffff;
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-radius: 2rem 2rem 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  z-index: 999;
}
.koko-language-selector__mb-menu--open {
  max-height: 90%;
}
.koko-language-selector__mb-menu-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none;
}
.koko-language-selector__mb-menu-list::-webkit-scrollbar {
  display: none;
}
.koko-language-selector__mb-menu-list__header {
  padding: 2.5rem 1.5rem 1.5rem;
}
.koko-language-selector__mb-menu-list__header > .close-handler {
  display: block;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translate(-50%, -50%);
  width: 5.8rem;
  height: 0.4rem;
  border-radius: 0.4rem;
  background-color: #bdbbbb;
}
.koko-language-selector__mb-menu-list__header > h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1e1e1e;
  margin: 0;
}
.koko-language-selector__mb-menu-list__options {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}
.koko-language-selector__mb-menu-list__option {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.8rem 1.5rem;
  color: rgba(0, 0, 0, 0.7);
  border-top: 0.1rem solid #ccc9c9;
  cursor: pointer;
}
.koko-language-selector__mb-menu-list__option:last-of-type {
  border-bottom: 0.1rem solid #ccc9c9;
}
.koko-language-selector__mb-menu-list__option--active {
  background: rgba(112, 89, 231, 0.1);
}
.koko-language-selector__mb-menu-action {
  height: 7.2rem;
  padding: 1.5rem 1.5rem 0.5rem;
  box-shadow: 0 0.4rem 0.4rem 0.5rem rgba(0, 0, 0, 0.25);
}
.koko-language-selector__mb-menu-action > .btn-close {
  width: 100%;
  height: 5rem;
}
.koko-language-selector--sb-container {
  height: 100vh;
  max-width: 100%;
}
.koko-language-selector--sb-container .koko-language-selector {
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 1024px) {
  .koko-language-selector--sb-container .koko-language-selector {
    position: relative;
  }
}
.koko-page__countries {
  display: flex;
  flex-direction: column;
  padding-top: 15.2rem;
}
@media (min-width: 1440px) {
  .koko-page__countries {
    padding-top: 17.6rem;
  }
}
.koko-page__countries > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.koko-page__countries > .header > .description {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 0;
}
.koko-page__countries > .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}
.koko-page__countries > .content > .country-selector {
  width: 100%;
}
.koko-page__countries > .content > .country-selector .koko-select__menu-list {
  scrollbar-width: none;
}
.koko-page__countries > .content > .country-selector .koko-select__menu-list::-webkit-scrollbar {
  display: none;
}
.koko-page__countries > .content > .btn-continue {
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 3rem;
  width: 18.7rem;
  height: 6rem;
}
.koko-page__countries > .footer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 4.7rem;
}
.koko-page__countries > .footer > .link-to-faq {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #7b7b7b;
}
.koko-page__countries > .footer > .link-to-faq > a {
  font-size: 1.6rem;
  font-weight: 600;
}
.koko-page__helplines {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-top: 6rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .koko-page__helplines {
    padding-top: 14rem;
  }
}
@media (min-width: 1440px) {
  .koko-page__helplines {
    padding-top: 20.5rem;
  }
}
.koko-page__helplines > .title {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
}
.koko-page__helplines > .title > .topic-type {
  text-transform: capitalize;
}
.koko-page__helplines > .helplines {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 3rem 0;
  max-width: 46rem;
}
.koko-page__main {
  display: flex;
  flex-direction: column;
  padding-top: 15.2rem;
  position: relative;
}
@media (min-width: 1440px) {
  .koko-page__main {
    padding-top: 17.6rem;
  }
}
.koko-page__main > .language-selector {
  position: absolute;
  top: 3.6rem;
  right: 0;
}
@media (min-width: 1024px) {
  .koko-page__main > .language-selector {
    right: 7rem;
  }
}
.koko-page__main > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.koko-page__main > .header > .img-logo {
  width: 13.3rem;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 0.4rem 0.4rem rgba(112, 89, 231, 0.4));
}
@media (min-width: 1024px) {
  .koko-page__main > .header > .img-logo {
    width: 21.9rem;
  }
}
.koko-page__main > .header > .description {
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.koko-page__main > .content {
  flex: 1;
}
.koko-page__main > .footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 1rem;
}
.koko-page__main > .footer > .external-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.koko-page__main > .footer > .external-links > .link {
  font-size: 1.6rem;
  font-weight: 500;
}
.koko-page__main > .footer > .external-links > .divider {
  background: #000000;
  width: 0.1rem;
  height: 1.6rem;
}
.koko-page__main > .footer > .copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
}
.koko-page__main > .footer > .copyright > span {
  font-size: 1.2rem;
  font-weight: 500;
}
.koko-page__main > .footer > .copyright > .koko-link {
  margin-top: 0.9rem;
}
.koko-page__main > .footer > .copyright > .koko-link > img {
  width: 3.4rem;
  height: auto;
  object-fit: contain;
}
.koko-page__resources {
  display: flex;
  flex-direction: column;
  padding-top: 11rem;
}
@media (min-width: 1024px) {
  .koko-page__resources {
    padding-top: 15.2rem;
  }
}
@media (min-width: 1440px) {
  .koko-page__resources {
    padding-top: 17.6rem;
  }
}
.koko-page__resources > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.koko-page__resources > .header > .title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.koko-page__resources > .content {
  flex: 1;
}
.koko-page__resources .footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 4.7rem;
}
.koko-page__resources .footer > .link-to-faq {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #7b7b7b;
}
.koko-page__resources .footer > .link-to-faq > a {
  font-size: 1.6rem;
  font-weight: 600;
}
.koko-page__self-help-courses {
  display: flex;
  flex-direction: column;
  padding-top: 11rem;
}
@media (min-width: 1024px) {
  .koko-page__self-help-courses {
    padding-top: 15.2rem;
  }
}
@media (min-width: 1440px) {
  .koko-page__self-help-courses {
    padding-top: 17.6rem;
  }
}
.koko-page__self-help-courses > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.koko-page__self-help-courses > .header > .title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 3rem;
}
.koko-page__self-help-courses > .header > .subtitle {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 4rem;
  text-align: center;
}
.koko-page__self-help-courses > .content {
  flex: 1;
}
.koko-page__self-help-courses .footer {
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 5rem;
}
.koko-page__self-help-courses .footer > .comment {
  font-size: 1.6rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .koko-page__self-help-courses .footer > .comment {
    max-width: 42.8rem;
  }
}
.koko-page__self-help-courses .footer > .author {
  font-size: 1.6rem;
  font-weight: 600;
}
.koko-page__faq__instructions {
  flex: 1;
}
@media (min-width: 1024px) {
  .koko-page__faq__instructions {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 8rem 0;
  }
}
.koko-page__faq__instructions > .content {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 19.5rem;
}
@media (min-width: 1024px) {
  .koko-page__faq__instructions > .content {
    margin-top: 0;
  }
}
.koko-page__faq__instructions > .content > .title {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  max-width: 35rem;
}
.koko-page__faq__instructions > .content > .desc-texts {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 46rem;
}
.koko-page__faq__instructions > .content > .desc-texts > p {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.3em;
}
.koko-page__faq__instructions > .footer {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-top: 4rem;
}
@media (min-width: 1024px) {
  .koko-page__faq__instructions > .footer {
    flex-direction: row;
    gap: 2rem;
  }
}
.koko-page__faq__instructions > .footer > button {
  font-size: 1.6rem;
  font-weight: 700;
  width: 18.7rem;
  height: 6rem;
}
.koko-page__faq__introduction {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.koko-page__faq__introduction > .description {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2em;
  text-align: center;
  margin: 0;
}
@media (min-width: 768px) {
  .koko-page__faq__introduction > .description > br:first-of-type {
    display: none;
  }
}
.koko-page__faq__introduction > .btn-continue {
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 4rem;
  width: 18.7rem;
  height: 6rem;
}
.koko-page__faq__options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.koko-page__peer-support__instructions {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 15.2rem;
}
@media (min-width: 1440px) {
  .koko-page__peer-support__instructions {
    padding-top: 17.6rem;
  }
}
.koko-page__peer-support__instructions > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.koko-page__peer-support__instructions > .header > .title {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  max-width: 35rem;
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .koko-page__peer-support__instructions > .header > .title {
    max-width: 46rem;
  }
}
.koko-page__peer-support__instructions > .content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}
.koko-page__peer-support__instructions > .content .btn-continue {
  font-size: 1.6rem;
  font-weight: 700;
  width: 18.7rem;
  height: 6rem;
}
.koko-page__peer-support__instructions .footer {
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 7rem;
  padding-bottom: 5rem;
}
.koko-page__peer-support__instructions .footer > .comment {
  font-size: 1.6rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .koko-page__peer-support__instructions .footer > .comment {
    max-width: 44rem;
  }
  .koko-page__peer-support__instructions .footer > .comment > br {
    display: none;
  }
}
.koko-page__peer-support__instructions .footer > .author {
  font-size: 1.6rem;
  font-weight: 600;
}
.koko-page__peer-support__platforms {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 15.2rem;
}
@media (min-width: 1440px) {
  .koko-page__peer-support__platforms {
    padding-top: 17.6rem;
  }
}
.koko-page__peer-support__platforms > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.koko-page__peer-support__platforms > .header > .title {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  max-width: 35rem;
  margin-top: 3rem;
}
.koko-page__peer-support__platforms > .header > .subtitle {
  font-size: 1.8rem;
  font-weight: 500;
  max-width: 35rem;
  margin-top: 3rem;
  margin-bottom: 4rem;
  text-align: center;
}
.koko-page__peer-support__platforms > .content {
  flex: 1;
}
