.koko-page__self-help-courses {
  .flex-col();
  padding-top: 11rem;

  .screen-md({
    padding-top: 15.2rem;
  });
  .screen-xl({
    padding-top: 17.6rem;
  });

  > .header {
    .flex-col();
    align-items: center;

    > .title {
      .title-1();
      margin-top: 3rem;
    }
    > .subtitle {
      .body-1();
      margin-top: 0.5rem;
      margin-bottom: 4rem;
      text-align: center;
    }
  }
  > .content {
    flex: 1;
  }
  .footer {
    .flex-col();
    .flex-align-center();
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 5rem;

    > .comment {
      .body-2();
      font-style: italic;
      line-height: 1.2em;
      margin-top: 0;
      margin-bottom: 1rem;

      .screen-sm({
        max-width: 42.8rem;
      });
    }
    > .author {
      .caption-2();
    }
  }
}
