.koko-cta-list {
  .flex-col();
  width: 100%;

  &__item {
    .flex-align-center();
    .normal-transition();
    width: 100%;
    justify-content: space-between;
    height: 8rem;
    border-top: 0.1rem solid @gray-200;
    cursor: pointer;

    &:last-of-type {
      border-bottom: 0.1rem solid @gray-200;
    }

    &:hover {
      opacity: 0.8;
    }

    > .left-panel {
      .flex-align-center();
      gap: 1.5rem;

      > svg {
        margin-left: 1.2rem;
        width: 5rem;
        height: 5rem;
      }
      > span {
        .body-1();
        margin-left: 0.5rem;
        color: @black-100;
      }
    }
    > .right-panel {
      .flex-center();

      > svg {
        color: @green-100;
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  // storybook
  &--sb-container {
    width: 35rem;
    max-width: 100%;
  }
}
