.koko-page__resources {
  .flex-col();
  padding-top: 11rem;

  .screen-md({
    padding-top: 15.2rem;
  });
  .screen-xl({
    padding-top: 17.6rem;
  });

  > .header {
    .flex-col();
    align-items: center;

    > .title {
      .title-1();
      margin-top: 3rem;
      margin-bottom: 4rem;
    }
  }
  > .content {
    flex: 1;
  }
  .footer {
    .flex-col();
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 4.7rem;

    > .link-to-faq {
      .body-2();
      text-align: center;
      color: @gray-300;

      > a {
        .caption-2();
      }
    }
  }
}
