.koko-page__countries {
  .flex-col();
  padding-top: 15.2rem;

  .screen-xl({
    padding-top: 17.6rem;
  });

  > .header {
    .flex-col();
    align-items: center;

    > .description {
      .body-1();
      text-align: center;
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }
  > .content {
    .flex-col();
    align-items: center;
    margin-top: 3rem;

    > .country-selector {
      width: 100%;

      .koko-select__menu-list {
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    > .btn-continue {
      .title-2();
      margin-top: 3rem;
      width: 18.7rem;
      height: 6rem;
    }
  }
  > .footer {
    .flex-col();
    flex: 1;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 4.7rem;

    > .link-to-faq {
      .body-2();
      text-align: center;
      color: @gray-300;

      > a {
        .caption-2();
      }
    }
  }
}
